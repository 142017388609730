<template>
	<div class="app-layer" :class="{ 'is-active': showConnectionDetails }">
		<TheConnectionDetails v-if="showConnectionDetails" />
	</div>
	<div
		class="overlay-layer app-layer"
		:class="{ 'is-active': !showConnectionDetails }"
	>
		<NewConnection v-if="showNewConnectionModal" />

		<Suspense>
			<TheConnectionOverview v-show="showOverview" />
		</Suspense>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

import TheConnectionDetails from '@modules/ConnectionBuilder/materials/structures/TheConnectionDetails.vue';
import TheConnectionOverview from '@modules/ConnectionBuilder/materials/TheConnectionOverview.vue';
import NewConnection from '@modules/ConnectionBuilder/materials/structures/NewConnection.vue';

export default {
	name: 'TheConnectionBuilder',
	components: {
		TheConnectionDetails,
		TheConnectionOverview,
		NewConnection,
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether the form to
			 * create a new connection should be displayed
			 */
			showCreateNew: getStoreGetter('SHOW_CREATE_NEW', 'CB'),
			/**
			 * Boolean to indicate whether a connection is
			 * currently active
			 */
			connectionActive: getStoreGetter('CONNECTION_ACTIVE', 'CB'),
		}),
		/**
		 * Boolean to indicate whether the current
		 * user can create connections
		 */
		canEditConnection: function () {
			return usePermission('Upsert', 'ConnectionBuilder');
		},
		/**
		 * Boolean to indicate whether the current
		 * user can read (view) connections
		 */
		canReadConnection: function () {
			return usePermission('Read', 'ConnectionBuilder');
		},
		showNewConnectionModal: function () {
			return this.showCreateNew && this.canEditConnection;
		},
		showConnectionDetails: function () {
			return !this.showNewConnectionModal && this.connectionActive && this.canReadConnection;
		},
		showOverview: function () {
			return !this.showNewConnectionModal && !this.showConnectionDetails && this.canReadConnection;
		}
	},
	unmounted: function () {
		// reset store to default state when page is unmounted
		this.$store.dispatch(getStoreAction('RESET', 'CB'));
	},
};
</script>
