<template>
	<VOverlay
		:hasBackground="false"
		:hasJsonButton="false"
		:hasCloseButton="false"
		:closeOnBackgroundClick="false"
		:isLarge="true"
		:isVCentered="false"
		:hasFullwidthBody="true"
		:hasScrollableContent="true"
		v-model="overlayIsActive"
		:title="$t('cb.connectionList.title')"
		:useFocusTrap="false"
		:closeWithEscape="false"
	>
		<Suspense>
			<ConnectionList />
		</Suspense>
	</VOverlay>
</template>

<script>
import ConnectionList from '@modules/ConnectionBuilder/materials/structures/ConnectionList.vue';

export default {
	name: 'TheConnectionOverview',
	components: {
		ConnectionList,
	},
	data: function () {
		return {
			overlayIsActive: true,
		};
	},
};
</script>
