<template>
	<div class="page-with-options-wrapper">
		<TheAppOptions
			:backButtonText="$t('cb.connDetails.backToOverview')"
			:backClickedFn="closeConnection"
			:nameLabel="$t('cb.connDetails.connName')"
			v-model:name="connName"
			@update:name="updateName"
			:saveClickedFn="saveConnection"
			:editMode="connection.is_new"
			:showButtons="showButtons"
			:canBeSaved="false"
			:canBePublished="canBePublished && !hasErrors"
			:publishClickedFn="publishConnection"
			:canBeValidated="canBeValidated"
			:validateClickedFn="validateConnection"
			:validationErrorListItemIsClickable="false"
			:trimName="true"
		/>

		<div class="page-content js-page-content">
			<ContentBlock
				:size="3"
				:title="$t('cb.connDetails.connectionSettings')"
			>
				<div class="has-padding-top-1">
					<ConfigOptions 
						:options="configOptions"
						:disabled="!canBeEdited"
						v-model:valueModel="configModel"
						@update:valueModel="configChanged"
					/>
				</div>
				<div class="has-padding-top-1">
					<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('cb.connDetails.description')" />

					<VTextarea
						v-model="connection.description"
						:placeholder="$t('cb.connDetails.description')"
						class="has-margin-bottom-4"
						:isNarrow="true"
						@change="markConnAsUpdated"
						:disabled="!canBeEdited"
					/>
				</div>
			</ContentBlock>
			<ContentBlock
				:size="3"
				:title="$t('cb.connDetails.credentialSettings')"
				:hasFullwidthBody="true"
			>
				<VField
                    :class="{ 'has-errors': showErrors }"
                    class="has-margin-right-1 is-wide"
                >
                    <json-forms
                        :data="connection.flow_credential.settings"
                        :renderers="renderers"
                        :schema="jsonformsmethods.generateSchema(fields)"
                        :uischema="jsonformsmethods.generateUiSchema(fields, data, hasErrors, saved)"
                        validationMode="ValidateAndShow"
                        @input="onInput"
                        @change="onChange"
                        :readonly="!canBeEdited"
                    />
                </VField>

			</ContentBlock>
		</div>
	</div>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import { reactive, toRefs } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import {
	getStoreGetter,
	getStoreMutation,
	getStoreAction,
} from '@assets/scripts/store/config';
import TheAppOptions from '@materials/structures/TheAppOptions.vue';
import { JsonForms } from '@jsonforms/vue';
import {
    defaultStyles,
    mergeStyles,
    vanillaRenderers,
} from '@jsonforms/vue-vanilla';
import  jsonformsmethods from '@assets/scripts/components/jsonformsmethods';

const renderers = [
    ...vanillaRenderers,
    // here you can add custom renderers
];

// mergeStyles combines all classes from both styles definitions into one
const myStyles = mergeStyles(defaultStyles, {
    control: { label: 'label-floating' },
});

export default {
	name: 'TheConnectionDetails',
	components: {
		TheAppOptions,
		JsonForms,
	},
	data: function () {
		return {
			connName: '',
			fields: [],
			showErrors: false,
			hasErrors: false,
			renderers: Object.freeze(renderers),
			saved: false,
		};
	},
	setup: function () {
		const store = useStore();
		const { t } = useI18n();

		const state = reactive({
			/**
			 * Gets currently active connection
			 */
			connection: store.getters[getStoreGetter('CURRENT_CONNECTION', 'CB')],
			configOptions: [
				{
					value: 'is_internal',
					label: t('cb.connOptions.is_internal'),
					info: t('cb.connOptions.is_internal_info'),
				},
			],
			configModel: {},
		});
		state.configOptions.forEach((option) => {
			if (typeof state.connection[option.value] !== 'undefined') {
				state.configModel[option.value] = state.connection[option.value];
			}
		});

		return {
			...toRefs(state), jsonformsmethods,
		};
	},
	mounted: async function () {
		// name of the current connection
		this.connName =
			this.connection && this.connection.name
				? this.connection.name
				: '';
		// data is from the connection settings object or empty for new connection

		// set saved to true if data is not empty
		if (Object.keys(this.connection.flow_credential.settings).length !== 0) {
			this.saved = true;
		}

		// get the current credential type object
		const credentialType = await this.$store.dispatch(getStoreAction('CREDENTIAL_TYPE', 'CB'), this.connection.type);
		// fields of the current credential type object
		this.fields = credentialType.fields;

		// clear validation when connection is loded
		this.$store.dispatch(getStoreAction('CLEAR_VALIDATION'));
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether user modified the connection
			 */
			modified: getStoreGetter('MODIFIED', 'CB'),
			/**
			 * Boolean to indicate whether current connection
			 * can be edited
			 */
			canBeEdited: getStoreGetter('CAN_BE_EDITED', 'CB'),
			/**
			 * Boolean to indicate whether current connection
			 * can be published
			 */
			canBePublished: getStoreGetter('CAN_BE_PUBLISHED', 'CB'),
		}),
		showButtons: function () {
			return this.canBeEdited;
		},
		canBeValidated: function () {
			return this.canBeEdited || this.canBePublished
		},
	},
	methods: {
		markConnAsUpdated: function () {		
			// mark connection as modified whenever a
			// change is made
			this.$store.commit(
				getStoreMutation('MARK_MODIFIED', 'CB')
			);
		},
		configChanged: function () {
			Object.assign(this.connection, this.configModel);
			this.markConnAsUpdated();
		},
		closeConnection: function () {
			const closeOverlay = () => {
				// dispatch action to unset document
				this.$store.dispatch(getStoreAction('UNSET_CONNECTION', 'CB'));
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeOverlay();
			} else {
				// ask confirmation before closing if a change has been made
				// to the Document
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('cb.connDetails.close.confirm.title'),
					body: this.$t('cb.connDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'cb.connDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeOverlay();
					},
				});
			}
		},
		validateConnection: function () {
			// dispatch action to Validate connection
			if (this.hasErrors) {
				this.showErrors = true;
			}
			this.$store.dispatch(
				getStoreAction('VALIDATE_CURRENT_CONNECTION', 'CB')
			);
		},
		saveConnection: function () {
			if (this.hasErrors) {
				this.showErrors = true;
			} else {
				this.$store.dispatch(
					getStoreAction('SAVE_CURRENT_CONNECTION', 'CB')
				);
				this.saved = true;
			}
		},
		publishConnection: function () {
			// ask confirmation before publishing
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('cb.connDetails.publish.confirm.title'),
				body: this.$t('cb.connDetails.publish.confirm.body'),
				confirmButtonText: this.$t(
					'cb.connDetails.publish.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to publish connection
					this.$store.dispatch(
						getStoreAction('PUBLISH_CURRENT_CONNECTION', 'CB')
					);
				},
			});
		},
		updateName: function (newName) {
			// commit mutation in store
			this.$store.commit(getStoreMutation('SET_NAME', 'CB'), newName);
			this.markConnAsUpdated();
		},
		onChange(event) {
			this.connection.flow_credential.settings = event.data;
            this.hasErrors = event.errors.length > 0;
			this.markConnAsUpdated();
        },
        onInput() {
            this.markConnAsUpdated();
        },
	},
	provide() {
        return {
            styles: myStyles,
        };
    },
};
</script>
