<template>
	<VOverlay
		:title="$t('cb.newConnection.title')"
		:size="2"
		:isVCentered="false"
		:closeOnBackgroundClick="true"
		:hasBackground="true"
		:modelValue="true"
		@update:modelValue="overlayClosed"
		:modalHasSharpCorner="true"
		@keydown.meta.s.prevent.stop="submit"
	>
		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('cb.newConnection.whatName')" />

		<VInputString
			:isRequired="true"
			v-model:valueModel="connNameValue"
			:label="$t('cb.newConnection.nameInputLabel')"
			:trim="true"
			@update:valueModel="markModified"
		/>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('cb.newConnection.giveDescription')" />

		<VTextarea
			v-model="connDescription"
			:placeholder="$t('cb.newConnection.giveDescription')"
			class="has-margin-bottom-4"
			:isNarrow="true"
		/>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('cb.newConnection.whatConnection')" />

		<VField
			:label="$t('cb.newConnection.selectConnection')"
			:isFloating="true"
			:class="{ 'is-active': connValue }"
		>
			<VSelect :options="connOptions" v-model="connValue" />
		</VField>

		<template #footer>
			<VButton
				:text="$t('cb.newConnection.submitButton')"
				icon="chevron-right"
				:iconOnRight="true"
				classes="button-modal-footer"
				:isDisabled="!isValid"
				@clicked="submit"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_CREDENTIAL_TYPES } from '@modules/ConnectionBuilder/endpoints';

export default {
	name: 'NewConnection',
	data: function () {
		return {
			connOptions: [],
			connValue: false,
			connNameValue: '',
			connDescription: '',
		};
	},
	computed: {
		...mapGetters({
			modified: getStoreGetter('MODIFIED', 'CB'),
		}),
		isValid: function () {
			return this.connValue && this.connNameValue.trim();
		},
	},
	mounted: async function () {
		// get all available credential types objects
		const credentialTypes = await useApiAsync(GET_CREDENTIAL_TYPES);

		// format connectionsCredentials to use in VSelect component
		if (credentialTypes.length > 0) {
			this.connOptions = credentialTypes.map((type) => {
				return {
					value: type.name,
					text: type.name,
				};
			});
		}
	},
	methods: {
		markModified: function () {
			// no need to trigger this mutation more than once
			if (this.modified) return;

			// mark document as modified whenever a
			// change is made
			this.$store.commit(getStoreMutation('MARK_MODIFIED', 'CB'));
		},
		overlayClosed: function () {
			this.$store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'CB'),
				false
			);
		},
		submit: function () {
			if (!this.isValid) return;

			this.$store.dispatch(getStoreAction('NEW_CONNECTION', 'CB'), {
				name: this.connNameValue.trim(),
				description: this.connDescription.trim(),
				type: this.connValue,
			});
		},
	},
};
</script>
